/**
 * @author BOUCHER Clément <cboucher@inbenta.com>
 * @copyright Inbenta Technologies Inc.
 */

import App from './app';

/**
 * Main function.
 */
function main() {
  const app = new App();
  app.initialize();

  document.getElementById('menu-handler').addEventListener('change', function () {
    if (this.checked) {
      document.getElementsByClassName('inbenta-helpsite')[0].classList.add('menu-mobile-open');
    } else {
      document.getElementsByClassName('inbenta-helpsite')[0].classList.remove('menu-mobile-open');
    }
  });

  resizeHomepageCategoriesColumn();
  window.addEventListener('resize', resizeHomepageCategoriesColumn);

  taggingPlan();
}

function resizeHomepageCategoriesColumn() {
  let columnCategories = document.querySelector('.home-page .column--categories');
  if (columnCategories) {
    let siblingHeight = columnCategories.previousElementSibling.clientHeight + 56 + 24;
    columnCategories.style = 'height: ' + siblingHeight + 'px;';
  }
}

function taggingSanitize(input) {
  return input
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[ \/\\?:"',+&!#]/g, '_')
    .replace(/__/g, '')
    .replace(/^_+|_+$/g, '')
    .trim();
}

function taggingPlan() {
  // Page display event.
  if (typeof tagPageContext !== 'undefined') {
    tag.events.send('page.display', tagPageContext);

    // Search event.
    if (typeof iseTagContext !== 'undefined') {
      tag.events.send('internal_search_result.display', iseTagContext);
    }

    // Search results click.
    let resultsLinks = document.querySelectorAll('.results-page .inbenta-km__results__extended a.inbenta-km__faq');
    resultsLinks.forEach((link, pos) => {
      if (typeof iseTagContext !== 'undefined' || typeof iseTcContext !== 'undefined') {
        link.addEventListener('click', (event) => {
          if (typeof iseTagContext !== 'undefined') {
            tag.events.send('internal_search_result.click',
              Object.assign({}, iseTagContext, {
                'ise_click_rank': pos + 1
              })
            );
          }

          if (typeof iseTcContext !== 'undefined') {
            tC.event.internal_search_click(this, {
              'iseClickRank': pos + 1
            });
          }
        });
      }
    });

    // Clicks.
    let idLinks = [
      {
        'elem': 'link_2c',
        'type': 'exit',
        'event_placement': 'body',
      }, {
        'elem': 'link_linkedin',
        'type': 'exit',
        'event_placement': 'header',
      }, {
        'elem': 'link_all_resources',
        'type': 'navigation',
        'event_placement': 'body',
      },
    ];

    // links identified by ID
    idLinks.forEach((link) => {
      let elem = document.getElementById(link.elem);
      if (elem) {
        elem.addEventListener('click', (event) => {
          tag.events.send('click.' + link.type,
            Object.assign({}, tagPageContext, {
              'click': link.elem,
              'event_placement': link.event_placement,
            }));

          tC.event.click(this, {
            'clickName': link.elem,
            'click_type': link.type,
            'eventPlacement': link.event_placement,
          });
        });
      }
    });

    // rating buttons
    let ratingbuttons = document.querySelectorAll('.inbenta-km__rating .inbenta-km-button');
    ratingbuttons.forEach((button) => {
      button.addEventListener('click', (event) => {
        tag.events.send('click.action',
          Object.assign({}, tagPageContext, {
            'click': taggingSanitize(button.querySelector('.inbenta-km-button__text').textContent),
            'event_placement': 'body',
          }));
      });
    });

    // category contents
    let categoryContentLinks = document.querySelectorAll('.category-page a.inbenta-km__faq__title');
    categoryContentLinks.forEach((link) => {
      link.addEventListener('click', (event) => {
        tag.events.send('click.navigation',
          Object.assign({}, tagPageContext, {
            'click': taggingSanitize(link.textContent),
            'event_placement': 'body',
          }));
      });
    });

    // resources CTA links
    let resourcesLinks = document.querySelectorAll('.resource-content-page a.resource-image__link');
    resourcesLinks.forEach((link) => {
      link.addEventListener('click', (event) => {
        tag.events.send('click.download',
          Object.assign({}, tagPageContext, {
            'click': taggingSanitize(link.textContent),
            'event_placement': 'body',
          }));
      });
    });

    // solutions links
    let solutionLinks = document.querySelectorAll('.solutions-page a.inbenta-km__solution__content-link');
    solutionLinks.forEach((link) => {
      link.addEventListener('click', (event) => {
        let type = link.href.indexOf(location.hostname) < 0 ? 'click.exit' : 'click.navigation';

        tag.events.send(type,
          Object.assign({}, tagPageContext, {
            'click': taggingSanitize(link.textContent),
            'event_placement': 'body',
          }));
      });
    });

    // contact blocks links
    let contactBlockLinks = document.querySelectorAll('.contact-page a.inbenta-km__contact__option');
    contactBlockLinks.forEach((link) => {
      link.addEventListener('click', (event) => {
        tag.events.send('click.action',
          Object.assign({}, tagPageContext, {
            'click': taggingSanitize(link.textContent),
            'event_placement': 'body',
          }));
      });
    });

    // contact faq links
    let contactFaqLinks = document.querySelectorAll('.contact-page .inbenta-km__faq .inbenta-km__faq__title');
    contactFaqLinks.forEach((link) => {
      link.addEventListener('click', (event) => {
        tag.events.send('click.action',
          Object.assign({}, tagPageContext, {
            'click': taggingSanitize(link.textContent),
            'event_placement': 'body',
          }));
      });
    });

    // contact faq links
    let contactLinks = document.querySelectorAll('.contact-page a.inbenta-km__contact__block__links__link');
    contactLinks.forEach((link) => {
      link.addEventListener('click', (event) => {
        tag.events.send('click.exit',
          Object.assign({}, tagPageContext, {
            'click': taggingSanitize(link.textContent),
            'event_placement': 'body',
          }));
      });
    });
  }
}

// Ensures DOM is fully loaded before running app's main logic.
// Loading hasn't finished yet...
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', main);
} else {
  // `DOMContentLoaded` has already been fired...
  main();
}

