/**
 * @author BOUCHER Clément <cboucher@inbenta.com>
 * @copyright Inbenta Technologies Inc.
 */

import { post } from '../helpers/request';
import { rate } from '../helpers/tracking';

/**
 * Ratings class used to handle all ratings element within the website.
 */
export default class Ratings {
  constructor(options = {}) {
    // init options
    this.options = Object.assign(
      {},
      {
        selectors: {
          // main rating container
          container: '.inbenta-km__rating',
          // buttons wrapper list
          buttonsContainer: '.inbenta-km__rating__content',
          // button wrapper
          buttonWrapper: '.content__buttons__button-wrapper',
          // rating data elements
          ratingData: '.rating-data',
          // rating button
          button: '.inbenta-km-button',
        }
      },
      options,
    );
    // init listener
    this.#init();
  }

  /**
   * Initialize listeners for rating elements
   */
  #init() {
    const { selectors } = this.options;
    // get all ratings container
    const ratingContainers = document.querySelectorAll(selectors.container);

    // iterate each ratings container
    ratingContainers.forEach((container) => {
      // find the buttons container
      const buttonsContainer = container.querySelector(selectors.buttonsContainer);
      // find each rating buttons wrapper in the current container
      const buttons = buttonsContainer.querySelectorAll(selectors.buttonWrapper);
      // iterate each button to add 'click' event
      buttons.forEach((wrapper) => {
        // get rating data
        const ratingData = wrapper.querySelectorAll(selectors.ratingData);
        // get button and add event listener
        const button = wrapper.querySelector(selectors.button);
        button.addEventListener('click', () => {
          this.processRatingNextStep(container, buttonsContainer, ratingData);
        })
      })
    })
  }

  /**
   * Process to the next step
   *
   * @param {HTMLElement} container Rating container
   * @param {HTMLElement} buttonsContainer Buttons container
   * @param {NodeListOf<Element>|NodeListOf<HTMLElement>} ratingData Rating data clicked
   */
  processRatingNextStep(container, buttonsContainer, ratingData) {
    buttonsContainer.classList.toggle('inbenta-km__rating__content--hidden');
    if (ratingData[2].value === '1') {
      this.goToContactStep(container, ratingData);
    } else {
      this.goToThanksStep(container, ratingData);
    }
  }

  /**
   * Function to send tracking event on rating
   *
   * @param {string} trackingCode
   * @param {string} value
   * @param {?string} comment
   *
   * @return {Promise<any>|void}
   */
  sendTracking(trackingCode, value, comment = null) {
    return rate(trackingCode, value, comment);
  };

  /**
   * Function to display rating comment step
   *
   * @param {HTMLElement} container Rating container
   * @param {NodeListOf<Element>|NodeListOf<HTMLElement>} ratingData Current rating data
   */
  goToContactStep(container, ratingData) {
    const contactSection = container.querySelector('.inbenta-km__rating__contact');
    const commentLink = contactSection.querySelector('.contact__container__link-comment');

    contactSection.classList.toggle('inbenta-km__rating__contact--hidden');

    commentLink.addEventListener('click', () => {
      contactSection.classList.toggle('inbenta-km__rating__contact--hidden');
      this.goToCommentStep(container, ratingData);
    });
  }

  /**
   * Function to display rating comment step
   *
   * @param {HTMLElement} container Rating container
   * @param {NodeListOf<Element>|NodeListOf<HTMLElement>} ratingData Current rating data
   */
  goToCommentStep(container, ratingData) {
    const commentSection = container.querySelector('.inbenta-km__rating__comment');
    const commentButton = commentSection.querySelector('.inbenta-km-button');
    const commentInput = commentSection.querySelector('.comment__feedback__textarea');

    // We resets input for accepting new comment.
    commentInput.value = '';
    commentButton.classList.toggle('inbenta-km-button—-disabled');
    commentSection.classList.toggle('inbenta-km__rating__comment--hidden');
    commentInput.addEventListener('keydown', () => {
      // User can send his comment only if it is not empty.
      if (commentInput.value !== '') {
        commentButton.classList.remove('inbenta-km-button—-disabled');
      }
    });

    // We send a first tracking event to log value without comment.
    this.sendTracking(ratingData[1].value, ratingData[0].value);

    commentButton.addEventListener('click', () => {
      commentSection.classList.toggle('inbenta-km__rating__comment--hidden');
      this.goToThanksStep(container, ratingData, commentInput.value);
    });
  }

  /**
   * Function to display rating thanks step
   *
   * @param {HTMLElement} container Rating container
   * @param {NodeListOf<Element>|NodeListOf<HTMLElement>} ratingData Current rating data
   * @param {?string} comment Comment to send
   */
  goToThanksStep(container, ratingData, comment = null) {
    const thanksSection = container.querySelector('.inbenta-km__rating__thanks');
    thanksSection.classList.toggle('inbenta-km__rating__thanks--hidden');
    this.sendTracking(ratingData[1].value, ratingData[0].value, comment);
  }
}
